import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppConfig } from 'src/app/app.config';
import { ClaimsResponse } from './shared/claimsResponse.model';
import { TokenResponse } from './shared/tokenResponse.model';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  token: string;
  email: string;

  constructor(private readonly http: HttpClient, private readonly config: AppConfig) {}

  getDecryptToken(cdPresta: string, token: string): Observable<ClaimsResponse> {
    const headers = {
      'Content-Type': 'application/json',
    };
    const url = `${this.config.getConfig('apiEndpoint')}/api/claims?cdPresta=${cdPresta}&token=${token}`;
    return this.http.get<ClaimsResponse>(url, { headers });
  }

  getUserEmail(): string {
    return this.email;
  }

  setToken(token: string) {
    this.token = token;
  }

  updtateOptins(cdPresta: string, tokenUrl: string): Observable<string> {
    const body = {
      cdPresta,
      tokenUrl,
    };
    const url = `${this.config.getConfig('apiEndpoint')}/api/token`;
    return this.http.put<any>(url, body);
  }

  getGenerateToken(
    cdPresta: string,
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    callBack: string,
    optins: string,
    marque: string,
    confirmation: string
  ): Observable<TokenResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json, application/text; charset=utf-8');
    const httpOptions = {
      headers,
      responseType: 'text' as 'json',
    };
    const url =
      `${this.config.getConfig('apiEndpoint')}/api/token?` +
      [
        `cdPresta=${cdPresta}`,
        `userId=${userId}`,
        `firstName=${firstName}`,
        `lastName=${lastName}`,
        `email=${email}`,
        `callBack=${callBack}`,
        `optins=${optins}`,
        `marque=${marque}`,
        `confirmation=${confirmation}`,
      ].join('&');

    return this.http.get<TokenResponse>(url, httpOptions);
  }
}
