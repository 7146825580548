import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { catchError } from 'rxjs/operators';

import { AppService } from '../../app.service';
import { CmsService } from 'src/app/services/cms.service';
import { CmsDataService } from 'src/app/services/cms-data.service';
import { ClaimsResponse } from 'src/app/shared/claimsResponse.model';
import { Image } from 'src/app/shared/image.model';
import { Texte } from 'src/app/shared/texte.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  error = false;
  email = '';
  cdPresta = '';
  phrase1: string;
  phrase2: string;
  phrase3: string;
  confirmation: string;
  token: string;
  bouton: string;
  logo: string;
  brand: string;
  errorDesinscription: string;
  libelleError = '';
  tokenIsOK = false;
  textsLoaded = false;
  sent = false;
  className: string;

  constructor(
    private readonly service: AppService,
    private readonly route: ActivatedRoute,
    private readonly cmsService: CmsService,
    private readonly cmsDataService: CmsDataService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params.token && params.cdPresta) {
        this.cdPresta = params.cdPresta;
        this.token = params.token;
        this.service.getDecryptToken(this.cdPresta, this.token).subscribe(
          (infoToken: ClaimsResponse) => {
            this.email = infoToken.email;
            this.setBrand(infoToken.marque);
            this.loadCmsData();
            if (!infoToken.confirmation || infoToken.confirmation === 'false') {
              this.unsubscribe();
            } else {
              this.tokenIsOK = true;
            }
          },
          () => {
            this.error = true;
          }
        );
      } else {
        this.error = true;
      }
      this.loadTextes(null);
    });
  }

  unsubscribe() {
    this.service
      .updtateOptins(this.cdPresta, this.token)
      .pipe(
        catchError((e) => {
          this.error = true;
          this.libelleError = this.errorDesinscription;
          return e;
        })
      )
      .subscribe(() => {
        this.tokenIsOK = false;
        this.sent = true;
      });
  }

  private setBrand(marque: string) {
    if (marque === 'GD') {
      this.brand = 'diac';
      this.className = 'diac';
    } else if (marque === 'NI') {
      this.brand = 'nissan';
      this.className = 'nissan';
    }
  }

  private loadCmsData() {
    const key = this.brand + '.stopcomm';
    this.cmsService.getTexteByKeyContains(key).subscribe((textes: Texte[]) => {
      this.loadTextes(textes);
    });
    this.cmsService.getImageByKeyContains(key).subscribe((images: Image[]) => {
      this.logo = this.cmsDataService.getImageUrlByKey(images, key, this.cdPresta);
    });
  }

  private loadTextes(textes: Texte[]) {
    this.phrase1 = this.cmsDataService.getTexteWithSuffix(textes, 'stopcomm.phrase1', this.cdPresta, this.brand);
    this.phrase2 = this.cmsDataService.getTexteWithSuffix(textes, 'stopcomm.phrase2', this.cdPresta, this.brand);
    this.phrase3 = this.cmsDataService.getTexteWithSuffix(textes, 'stopcomm.phrase3', this.cdPresta, this.brand);
    this.confirmation = this.cmsDataService.getTexteWithSuffix(
      textes,
      'stopcomm.confirmation',
      this.cdPresta,
      this.brand
    );
    this.bouton = this.cmsDataService.getTexteWithSuffix(textes, 'stopcomm.bouton', this.cdPresta, this.brand);
    this.libelleError = this.cmsDataService.getTexteWithSuffix(textes, 'stopcomm.erreur', this.cdPresta, this.brand);
    this.errorDesinscription = this.cmsDataService.getTexteWithSuffix(
      textes,
      'stopcomm.erreur-desincription',
      this.cdPresta,
      this.brand
    );
    this.textsLoaded = true;
  }
}
