import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, forkJoin } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AppConfiguration } from 'src/app/shared/appConfiguration.model';
import { EnvConfiguration, Environment } from 'src/app/shared/envConfiguration.model';
import { CmsDefaultData } from 'src/app/shared/cmsDefaultData.model';

@Injectable()
export class AppConfig {
  env: EnvConfiguration = null;
  private config: AppConfiguration = null;
  private cmsDefaultData: CmsDefaultData = null;

  constructor(private readonly http: HttpClient) {}

  /**
   * Use to get the data found in the second file (config file)
   */
  getConfig(key: keyof AppConfiguration) {
    if (!this.config) {
      throw new Error('No configuration, something went wrong');
    }
    return this.config[key];
  }

  getCmsDefaultData(key: keyof CmsDefaultData) {
    return this.cmsDefaultData[key];
  }

  load() {
    return new Promise((resolve) => {
      this.http
        .get('config/env.json')
        .pipe(
          catchError((error: any): any => {
            resolve(true);
            return throwError(error || 'Server error');
          })
        )
        .subscribe((envResponse: EnvConfiguration) => {
          this.env = envResponse;
          const obs = [...this.loadConfiguration(envResponse.env), ...this.loadCmsDefaultData()];

          forkJoin(obs).subscribe(
            (error: any) => {
              resolve(error);
              return throwError(error || 'Server error');
            },
            () => {
              resolve(true);
            }
          );
        });
    });
  }

  private loadConfiguration(env: Environment) {
    let request: any = null;
    const urlStart = 'config/config.';
    const urlEnd = '.json';

    switch (env) {
      case 'production':
      case 'development':
      case 'acceptance':
      case 'validation':
        {
          request = this.http.get(urlStart + env + urlEnd).pipe(
            tap((responseData: AppConfiguration) => {
              this.config = responseData;
            })
          );
        }
        break;
    }

    if (request) {
      return [request];
    } else {
      return [throwError(`No file config.${env}.json exists`)];
    }
  }

  private loadCmsDefaultData() {
    return [
      this.http.get('config/strapi.json').pipe(
        tap(
          (responseData: CmsDefaultData) => {
            this.cmsDefaultData = responseData;
          },
          (error: CmsDefaultData) => Observable.throw(error || 'Error reading CMS data file')
        )
      ),
    ];
  }
}
