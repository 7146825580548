import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { TokenResponse } from 'src/app/shared/tokenResponse.model';
import { ClaimsResponse } from 'src/app/shared/claimsResponse.model';

@Component({
  selector: 'app-token-generation',
  templateUrl: './token-generation.component.html',
  styleUrls: ['./token-generation.component.scss'],
})
export class TokenGenerationComponent {
  form = this.fb.group({
    cdPresta: ['', Validators.required],
    token: [''],
    userId: [''],
    lastName: ['', Validators.required],
    firstName: ['', Validators.required],
    email: ['', Validators.required],
    callBack: [''],
    optins: [''],
    marque: [''],
    confirmation: ['', Validators.required],
  });

  token: FormControl;

  constructor(private readonly fb: FormBuilder, private readonly appService: AppService) {}

  get tokenIsNull(): boolean {
    return !this.form.get('token').value;
  }

  generateToken() {
    const cdPresta = this.form.get('cdPresta').value;
    const userId = this.form.get('userId').value;
    const lastname = this.form.get('lastName').value;
    const firstname = this.form.get('firstName').value;
    const email = this.form.get('email').value;
    const callback = this.escapeCharacters(this.form.get('callBack').value);
    const optins = this.form.get('optins').value;
    const marque = this.form.get('marque').value;
    const confirmation = this.form.get('confirmation').value;

    this.appService
      .getGenerateToken(cdPresta, userId, firstname, lastname, email, callback, optins, marque, confirmation)
      .subscribe(
        (token: TokenResponse) => {
          this.form.get('token').setValue(token);
        },
        (error) => console.error(error)
      );
  }

  escapeCharacters(callback : string) {
  
    if(callback == null){
       return callback;
    }
 
    return callback
    .replace(/\//g, "7YST3")
    .replace(/%/g, "5YST3")
    .replace(/&/g, "26ST3")
    .replace(/:/g, "3AST3")
    .replace(/;/g, "3BST3")
    .replace(/=/g, "3DST3")
    .replace(/\?/g, "4XST3");
  
  }


  decryptToken() {
    const cdPresta = this.form.get('cdPresta').value;
    const token = this.form.get('token').value;
    this.appService.getDecryptToken(cdPresta, token).subscribe(
      (claims: ClaimsResponse) => {
        this.form.get('userId').setValue(claims.userId);
        this.form.get('firstName').setValue(claims.firstName);
        this.form.get('lastName').setValue(claims.lastName);
        this.form.get('email').setValue(claims.email);
        this.form.get('callBack').setValue(claims.callBack);
        this.form.get('optins').setValue(claims.optins);
        this.form.get('marque').setValue(claims.marque);
        this.form.get('confirmation').setValue(claims.confirmation);
      },
      (err) => console.error(err)
    );
  }

  goToLink() {
    const cdPresta = this.form.get('cdPresta').value;
    const token = this.form.get('token').value;
    const url = '/stopcomm';
    window.open(url + '?cdPresta=' + cdPresta + '&token=' + token, '_blank');
  }

  changeFormValues(input?: 'token') {
    if (input) {
      this.form.get('userId').setValue('');
      this.form.get('firstName').setValue('');
      this.form.get('lastName').setValue('');
      this.form.get('email').setValue('');
      this.form.get('callBack').setValue('');
      this.form.get('optins').setValue('');
      this.form.get('marque').setValue('');
      this.form.get('confirmation').setValue('');
    } else {
      this.form.get('token').setValue('');
    }
  }
}
