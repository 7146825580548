import { AppConfig } from 'src/app/app.config';
import { Texte } from '../shared/texte.model';
import { Image } from '../shared/image.model';

export class CmsDataService {
  texteDefaultValues: { [key: string]: string } = {};
  imageDefaultValues: { [key: string]: string } = {};

  constructor(private readonly config: AppConfig) {
    this.texteDefaultValues = this.config.getCmsDefaultData('textesParDefaut') as { [key: string]: string };
    this.imageDefaultValues = this.config.getCmsDefaultData('imagesParDefaut') as { [key: string]: string };
  }

  getTexteWithSuffix(listCms: Texte[], key: string, cdPresta: string, brand: string): string {
    let valueWithCdPrestaAndBrand = '';
    let defaultValue = '';
    const keyWithCdPrestaAndBrand = `${brand}.${key}.${cdPresta}`;
    valueWithCdPrestaAndBrand =
      listCms && listCms.find((texte: Texte) => texte.cle === keyWithCdPrestaAndBrand)
        ? listCms.find((texte: Texte) => texte.cle === keyWithCdPrestaAndBrand).valeur
        : '';
    defaultValue = this.texteDefaultValues[key];
    return valueWithCdPrestaAndBrand || defaultValue;
  }

  getImageWithSuffix(listCms: Image[], key: string, cdPresta: string): Image {
    let valueWithCdPresta: any;
    const keyWithCdPresta = `${key}.${cdPresta}`;
    valueWithCdPresta =
      listCms && listCms.find((image: Image) => image.cle === keyWithCdPresta)
        ? listCms.find((image: Image) => image.cle === keyWithCdPresta)
        : null;
    return valueWithCdPresta;
  }

  getImageUrlByKey(list: Image[], key: string, cdPresta: string) {
    const localImage = this.getImageWithSuffix(list, key, cdPresta);
    if (localImage) {
      if (!localImage.image) {
        // On est sur une image renseignée par URL dans Strapi
        return localImage.url;
      } else {
        // On est sur une image uploadée via Strapi
        return this.getImagesUrl() + localImage.image.url;
      }
    } else {
      return '';
    }
  }

  getImagesUrl() {
    return this.config.getConfig('cmsApiUploadEndpoint');
  }
}
